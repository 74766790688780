<template>
  <div>
    <v-divider></v-divider>
    <v-footer light>
      <v-layout
        wrap
        justify-center
        style="font-family: poppinsmedium; font-size: 14px; color: #000"
      >
        <v-flex xs12 md4 itemscope text-left>
          WWS &copy;{{ new Date().getFullYear() }}
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 md4 text-left text-sm-right>
          Powered by
          <span style="color: #1794ff"> Leopard Tech Labs Pvt. Ltd </span>
        </v-flex>
        <v-flex xs12 md4 text-left text-sm-right>
         
          <span style="color: red;cursor: pointer;"  @click="appLogout"> Logout </span>
        </v-flex>
      </v-layout>
    </v-footer>
  </div>
</template>
<script>
  import store from "../../store";
  export default {
    data() {
      return {
        user: {},
      };
    },
    computed: {
      appLogin() {
        return store.state.isLoggedIn;
      },
      appUser() {
        return store.state.userData.user;
      },
    },
    methods: {
      appLogout() {
        store.commit("logoutUser", true);
      },
    },
  };
  </script>
