<template>
  <div>
    <v-app id="app">
      <span
        v-if="
          $route.name != 'Login' &&
          $route.name != 'SignUp' &&
          $route.name != 'ForgotPassword' &&
          $route.name != 'Home'
        "
      >
        <AppHeader />
        <v-divider></v-divider>
      </span>
      <span style="min-height: 100vh">
        <router-view  v-bind:storage="sideNav" />
      </span>
      <AppFooter
        v-if="
          $route.name != 'Login' &&
          $route.name != 'SignUp' &&
          $route.name != 'ForgotPassword'
        "
      />
    </v-app>
  </div>
</template>
<script>
import AppHeader from "./components/Common/appHeader";
import AppFooter from "./components/Common/appFooter";
import axios from "axios";
import store from "./store";

import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      sideNav: false,
    };
  },
  beforeMount() {
    if (typeof localStorage.getItem("token") == "string") {
      axios({
        method: "POST",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            store.commit("userData", response.data.userdata);
            store.commit("userType", response.data.userdata.user.role);
            store.commit("menu", response.data.menu);

          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {},
};
</script>
<style >


.v-application .primary--text {
  color: #68d389 !important;
  caret-color: #68d389 !important;
}
.v-application .accent {
  background-color: #68d389 !important;
  border-color: #68d389 !important;
}
.v-application .primary {
  background-color: #68d389 !important;
}
#myIcon .v-application .primary {
  background-color: red;
}
</style>